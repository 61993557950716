<template>
  <div class="about">
    <about-us-hero />
    <about-section />
  </div>
</template>
<script>
import AboutUsHero from "@/components/about/AboutUsHero.vue";
import AboutSection from "@/components/about/AboutSection.vue";
export default {
  components: {
    AboutUsHero,
    AboutSection,
  },
  metaInfo: {
    title: "About Us",
  },
};
</script>
